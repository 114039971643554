@import '../../includes/all';

$page-bg: white;

html {
  // iFrame fix
  width: 1px;
  min-width: 100%;
}

@include mobile {
  select {
    -webkit-appearance: none;
       -moz-appearance: none;
  }

  .flex {
    padding: 1px 0;
  }
}

@include desktop {
  h1 {
    font-size: 36px;
    padding: 15px;
    line-height: 30px;
  }

  #plugin-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #background2 {
    display: none;
  }

  body {
    min-width: 767px;
  }
}

body {
  background-color: $page-bg;
}

header {
  // Never hide header on landing page
  display: block !important;
}

$header-height: 100px;

#wrapper {
  position: relative;
  text-align: center;
  min-height: calc(100vh - #{$header-height});
  min-width: 300px;

  @include desktop {
    height: 70vh;
    min-height: 500px;
    max-height: 700px;
  }

  @include mobile {
    padding: 1px 0;

    @media screen and (max-height: 430px) {
      padding-top: 10px;
    }
  }

  #background,
  #background2 {
    background: no-repeat no-repeat center center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #background {
    @include mobile {
      background-image: url($step1-mobile-bg);
    }

    @include desktop {
      background-image: url($step1-bg);
    }
  }
}

#content {
  margin: 15px;

  @include desktop {
    margin: 0 !important;
    height: 100%;
  }
}

#form-container {
  @include mobile {
    position: relative;
  }

  @include desktop {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    left: 0;
    right: 0;
  }
}

#beginTekst {
  position: relative;
  color: white;
  width: 100%;
  height: calc(100% - 222px);

  h1 {
    text-shadow: 0 0 3px rgba(0, 0, 0, .5);

     > span:last-child {
      @include font-light;
      font-size: 20px;
      text-transform: none;

       > button {
        font-size: 16px;
        margin-left: 5px;
        line-height: normal;
      }
    }
  }

  @include mobile {
    h1 {
      font-size: 35px;
    }

     > p {
      font-size: 20px;
      text-shadow: 0 0 3px rgba(0, 0, 0, .5);
      margin: 0;
      padding: 20px 0;
    }
  }

   > img {
    width: 717px;
    max-width: 90%;
    display: none;
  }

  @include desktop {
    height: calc(100% - 220px);
    transition: .4s height linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 960px;
    margin: auto;
    text-align: left;

    h1 {
      @media screen and (max-height: 768px) {
        padding-bottom: 50px;
      }
    }

    &.flight {
      height: calc(100% - 249px);
    }

    &.retour {
      // When retour is open
      height: calc(100% - 236px);

      &.flight {
        height: calc(100% - 289px);
      }
    }

    img {
      display: initial;
    }

    p {
      display: none;
    }
  }
}
