@import '../../includes/all';

$v-gutter: 30px;

#three-steps {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;

  > div {
    text-align: center;
    display: inline-block;
    vertical-align: top;

    &:first-child {
      width: calc(#{100% / 3 * 2} - #{$v-gutter / 2});
      margin-right: $v-gutter;

      > div > div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding-top: 5px;
        border-top: 1px solid white;
      }
    }

    &:last-child {
      width: calc(#{100% / 3} - #{$v-gutter / 2});

      > div > span {
        padding-top: 5px;
        border-top: 1px solid white;
        display: inline-block;
      }
    }
  }
}
