@import '../../includes/all';

$heading-color: $theme-text;
$comingsoon-color: #9b9b9b;
$electric-color: $theme-text;

#destinations {
  padding: 15px;
  max-width: 930px;
  margin: auto;
  text-align: center;
  overflow: hidden;

  h2 {
    font-size: 36px;

    > span {
      font-size: 22px;
      display: block;
    }
  }

  > div {
    h3 {
      color: $heading-color;
      font-size: 36px;
      text-transform: none;

      &:first-child {
        margin-top: 0;
      }
    }

    > div {
      max-width: (90% / 3);
      display: inline-block;
      vertical-align: top;
      text-align: left;

      &:not(:last-child) {
        margin-right: 5%;
      }

      > div {
        font-weight: bold;
        font-size: 16px;

        &:first-child {
          margin-top: 40px;
        }

        > ul {
          font-weight: normal;
          list-style-type: none;
          padding: 0;
          margin: 0 0 15px;

          &.coming-soon {
            color: $comingsoon-color;
          }

          li {
            overflow: visible;

            &.electric::after {
              content: ' E';
              color: $electric-color;
              font-weight: bold;
            }

            &.coming {
              color: $comingsoon-color;

              &::after {
                content: ' coming';
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
